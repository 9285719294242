<ion-app *ngIf="isLogged$ | async as isLogged; else login">
  {{ mouseOvered }}
  <ion-split-pane
    [class.menu-open]="mouseOvered"
    when="xs"
    contentId="main-content"
    >
    <ion-menu
      (mouseover)="mouseOvered = true"
      (mouseout)="mouseOvered = false"
      contentId="main-content"
      type="overlay"
    >
      <ion-content>
        <app-sidebar></app-sidebar>
      </ion-content>
    </ion-menu>

    <main class="ion-page" id="main-content">
      <!-- <app-layout-header id="main-content"></app-layout-header> -->
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </main>
  </ion-split-pane>
  <!-- <ng-template class="show-login" #login>
    <app-login></app-login>
  </ng-template> -->

  <!-- <div class="show-login" *ngIf="!isLogged$" >
    <app-login></app-login>
  </div> -->
</ion-app>

<ng-template #login>
  <!-- <app-login></app-login> -->
  <router-outlet></router-outlet>
</ng-template>


<app-loading-screen></app-loading-screen>



