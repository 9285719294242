import { Observable, throwError } from "rxjs";
import { UserAdmin } from "src/app/models/useradmin";
import { AuthStore } from "../Auth.store";

export abstract class AdminService {

  protected authStore: AuthStore;
  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

    protected handleError(error: any) {
      return throwError(error);
    }

    protected log(message: string) {
        console.log('============= message response ============');
        console.log(message);
    }

  public abstract carregarAdmin(adminId: number): Observable<UserAdmin>;
  public abstract criaAdmin(adminUser: UserAdmin): Observable<UserAdmin>;
  public abstract atualizarAdmin(adminUserId: number, newUserAdmin: UserAdmin): Observable<UserAdmin>;
  public abstract alterarSenha(currentPassword: string, newPassword: string): Observable<any>;
  public abstract deletarAdmin(adminUserId: number): void;
  public abstract recuperarSenha(email: string): Observable<any>;

  public setUserAdmin(user: UserAdmin) {
    this.authStore.setUserAdmin(user);
  }
  public getUserAdmin(): Observable<UserAdmin> {
    return this.authStore.userAdmin$;
  }
}
