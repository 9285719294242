<ion-content class="ion-padding">
  <ion-icon
    class="close-icon"
    name="close-outline"
    (click)="cancel()"
  ></ion-icon>

  <h2>Solicitações de Trocas de Janeiro</h2>

  <!-- <div class="wrapper">
    <span>15/02/2020</span>

    <ion-item lines="none">
      <div class="day-wrapper">
        <p>SEX</p>
        <span>12</span>
      </div>

      <ion-avatar>
        <img
          alt="Silhouette of a person's head"
          src="https://ionicframework.com/docs/img/demos/avatar.svg"
        />
      </ion-avatar>

      <ion-label>
        <h3>Anne Karine Menezes S. Batista</h3>
        <ion-chip [style.background]="'#fbb845'">D - 05:00 a 15:00</ion-chip>
        <ion-chip [style.background]="'#fbb845'">UTI GERAL</ion-chip>
      </ion-label>
    </ion-item>

    <div class="arrows-wrapper">
      <ion-icon name="arrow-back-outline"></ion-icon>
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </div>

    <ion-item lines="none">
      <div class="day-wrapper">
        <p>SEX</p>
        <span>12</span>
      </div>

      <ion-avatar>
        <img
          alt="Silhouette of a person's head"
          src="https://ionicframework.com/docs/img/demos/avatar.svg"
        />
      </ion-avatar>

      <ion-label>
        <h3>Daniela de Souza Pinto</h3>
        <ion-chip [style.background]="'#1EB39E'">M - 05:00 a 12:00</ion-chip>
        <ion-chip [style.background]="'#63B2F7'">SEMI INTENSIVA GERAL</ion-chip>
      </ion-label>
    </ion-item>

    <ion-select mode="md" lines="none" value="a" fill="solid">
      <ion-select-option value="a">Pendente</ion-select-option>
    </ion-select>
  </div> -->


  <div class="items" *ngIf="(solicitacoes$ | async) as solicitacoes">
    <div class="wrapper" *ngFor="let item of solicitacoes">
      <span>{{ item.data | date: 'dd/MM/yy'}}</span>

      <ion-item lines="none">
        <div class="day-wrapper">
          <p>{{ item.dataTroca | date: 'EEEEEE' | uppercase}}</p>
          <span>{{ item.dataTroca | date: 'dd' }}</span>
        </div>

        <ion-avatar>
          <img
            alt="Silhouette of a person's head"
            src="https://ionicframework.com/docs/img/demos/avatar.svg"
          />
        </ion-avatar>

        <ion-label>
          <h3>{{ item.colaboradores.to.nome }}</h3>
          <ion-chip [style.background]="'#fbb845'">{{ item.turno.to }}</ion-chip>
          <ion-chip [style.background]="'#fbb845'">{{ item.setor.to }}</ion-chip>
        </ion-label>
      </ion-item>

      <div class="arrows-wrapper">
        <ion-icon name="arrow-back-outline"></ion-icon>
        <ion-icon name="arrow-forward-outline"></ion-icon>
      </div>

      <ion-item lines="none">
        <div class="day-wrapper">
          <p>{{ item.dataTroca | date: 'EEEEEE' | uppercase}}</p>
          <span>{{ item.dataTroca | date: 'dd' }}</span>
        </div>

        <ion-avatar>
          <img
            alt="Silhouette of a person's head"
            src="https://ionicframework.com/docs/img/demos/avatar.svg"
          />
        </ion-avatar>

        <ion-label>
          <h3>{{ item.colaboradores.from.nome }}</h3>
          <ion-chip [style.background]="'#1EB39E'">{{ item.turno.from}}</ion-chip>
          <ion-chip [style.background]="'#63B2F7'">{{ item.setor.from}}</ion-chip>
        </ion-label>
      </ion-item>

      <ion-select mode="md" lines="none" value="a" fill="solid">
        <ion-select-option value="a">Pendente</ion-select-option>
      </ion-select>
    </div>
  </div>

</ion-content>
