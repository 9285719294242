import { Injectable } from "@angular/core";
import { UsuarioService } from "./usuario.service";
import { BehaviorSubject, Observable, catchError, map, of, tap, throwError } from "rxjs";
import { Usuario } from "src/app/models/usuario";
import { HttpClient } from "@angular/common/http";
import { AuthStore } from "../Auth.store";
import { EndPoint, getEndpoint } from "src/app/models/endpoint";
import { LoadingService } from "../loading.service";

@Injectable()
export class UsuarioHttpService extends UsuarioService {


  private isLogged_ = new BehaviorSubject<boolean>(false);
  isLogged$: Observable<boolean> = this.isLogged_.asObservable();

  constructor(private http: HttpClient, private authStore: AuthStore, private loadingService: LoadingService) {
    super()
  }

  public login(email: string, password: string): Observable<Usuario> {
    this.loadingService.presentLoading();
    return this.http.post<Usuario>(getEndpoint(EndPoint.LOGIN), {
      username: email,
      password: password
    }).pipe(
      map((res) => {
       if(res){
        return res
       }
       return {} as Usuario
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading();
        const message = "Email/Senha incorreto."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.loadingService.dismissLoading();
        this.authStore.setUserData(res);
        this.authStore.setIsLogged(true);
      })
    );
  }

  public signup(email: string, password: string): Observable<Usuario> {
    this.loadingService.presentLoading();
    return this.http.post<Usuario>(getEndpoint(EndPoint.NEW_LOGIN), { email, password}).pipe(
    map((res) => {
       if(res){
          return res
        }
        return {} as Usuario
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading();
        const message = "Email/Cpf ja cadastrado";
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((_) => {
        this.loadingService.dismissLoading();
      })
    )
  }


  public override isLogged(): Observable<boolean> {
    return this.authStore.isLogged$;
  }

  public override setLogged(logged: boolean): void {
    this.authStore.setIsLogged(logged)
  }

  public override logout(): void {
    // this.authStore.setUserData(null);
    // this.this.authStore.isLogged$.next(false);
    this.authStore.logout();
  }

  public getUser(): Observable<Usuario> {
    return this.authStore.user$;
  }


}
