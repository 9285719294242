import { Component, Input, OnInit } from '@angular/core';
import { find, tap } from 'rxjs';
import { getColor } from 'src/app/models/colors';
import { Turno } from 'src/app/models/turno';
import { TurnoService } from 'src/app/services/turno/turno.service';

@Component({
  selector: 'table-export-pdf',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent  implements OnInit {


  headColor: string = '#f7a941';
  @Input() tableItems: any;
  @Input() data: any;
  @Input() shifts!: Turno[];
  titleTable: string = ''
  turnos: Turno[] = [];

  constructor(
    private turnoService: TurnoService
  ) {
  }

  ngOnInit() {
    console.log('data table', this.data)
    if(this.data) {
      this.turnoService.getSetorById(this.data.setorId).pipe(
        tap((r) => {
          this.titleTable = r.nome;
          this.headColor = getColor(r.cor)
        })
      ).subscribe()
    }
  }

  getTurno(turnoId: number) {
    const turno = this.shifts.find(r => r.id == turnoId)
    if(!turno) return {
      sigla: ''
    }
    const newData = {
      ...turno,
      corFormated: getColor(turno!.cor)
    }
    return newData;
  }

  isHoliday(sys: string): boolean {
    if(sys == 'LM' || sys == 'FE') {
      return true;
    }
    return false;
  }

  isPoint(sys: string): boolean {
    if(sys == 'MT' || sys == 'SD') {
      return true;
    }
    return false;
  }

}
