import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable, tap } from 'rxjs';
import { Empresa } from 'src/app/models/empresa';
import { UserAdmin } from 'src/app/models/useradmin';
import { Usuario } from 'src/app/models/usuario';
import { SelectEmpresaComponent } from 'src/app/modules/empresa/pages/select-empresa/select-empresa.component';
import { AdminService } from 'src/app/services/admin/admin.service';
import { EmpresaService } from 'src/app/services/empresa/empresa.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderLayoutComponent  implements OnInit {

  user$!: Observable<UserAdmin>;
  empresaName!: string;

  constructor(
    private useradmin: AdminService,
    private empresaService: EmpresaService,
    private route: Router,
    private modalCtrl: ModalController
    ) { }

  ngOnInit() {
    this.user$ = this.useradmin.getUserAdmin();
    this.empresaService.getEmpresaSelect().pipe(
      tap((res) => this.empresaName = res.nome)
    ).subscribe()
  }

  async sendTo() {
    const modal = await this.modalCtrl.create({
      component: SelectEmpresaComponent,
      cssClass: 'config-default',
      backdropDismiss: false,
    })
    modal.present();
    // this.route.navigate(['empresa','select'])
  }

}
