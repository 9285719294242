import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { JornadaService } from "./jornada.service";
import { Jornada } from "src/app/models/jornada";

@Injectable()
export class JornadaMockService extends JornadaService {

    private jornadas: BehaviorSubject<Jornada[]> = new BehaviorSubject<Jornada[]>([]);

    public jornadas$: Observable<Jornada[]> = this.jornadas.asObservable();

    constructor() {
        super();
    }

    public override carregaJornadas(): Observable<Jornada[]> {
      return this.jornadas$;
    }

    public override criaJornada(jornada: Jornada): Observable<Jornada> {
      let jornadas = this.jornadas.getValue();
      if(jornadas.length === 0){
          jornada.id = 1;
      }
      else{
          jornada.id = jornadas[jornadas.length -1].id + 1;
      }
      jornadas.push(jornada)
      this.jornadas.next(jornadas);
      console.log(this.jornadas)
      return of(jornada);
    }

    public override filtrarJornada(jornadaId: number): Observable<Jornada> {
      const jornadas = this.jornadas.getValue();
      const jornada = jornadas.find(jornada => jornada.id === jornadaId)!

      return of(jornada)
    }

    public override atualizarJornada(jornadaId: number, changes: Partial<Jornada>): Observable<Jornada> {
      const jornadas = this.jornadas.getValue();
      const index = jornadas.findIndex(jornada => jornada.id === jornadaId);
      const newJornada: Jornada = {
        ...jornadas[index],
        ...changes
      }

      const newJornadas: Jornada[] = jornadas.slice(0);
      newJornadas[index] = newJornada;
      this.jornadas.next(newJornadas);
      return of(newJornada)
    }

    public override deletarJornada(jornadaId: number): void {
      const jornadas = this.jornadas.getValue();
      const jornadasFiltered = jornadas.filter(jornada => jornada.id != jornadaId);
      this.jornadas.next(jornadasFiltered)
    }

}
