import { Observable, map, throwError } from "rxjs";
import { Regra } from "src/app/models/regra";
import { RequestChange } from "src/app/models/request-change";
import { Setor } from "src/app/models/setor";
import { Turno } from "src/app/models/turno";
import { ScaleStore } from "../Scale.store";
import { itemColaborador } from "src/app/models/itens_colaboradores";
import { addColaborador } from "src/app/models/add-colaborador";

export abstract class TurnoService {

  protected scaleStore: ScaleStore;
  constructor(scaleStore: ScaleStore) {
    this.scaleStore = scaleStore;
  }

  protected handleError(error: any) {
    return throwError(error);
  }

  protected log(message: string) {
    console.log('============= message response ============');
    console.log(message);
  }

  public abstract carregaTurnos(escaldId: number): Observable<Turno[]>;
  public abstract criaTurno(turno: Turno): Observable<Turno>;
  public abstract atualizarTurno(turnoId: number, newTurno: Turno): Observable<Turno>;
  public abstract filtrarTurno(turnoId: number): Observable<Turno>;
  public abstract deletarTurno(turnoId: number): void;

  public abstract carregaSetores(escaldId: number): Observable<Setor[]>;
  public abstract carregaSetorPorEscala(escalaId: number, setorId: number): Observable<Setor>;
  public abstract criaSetor(setor: Setor): Observable<Setor>;
  public abstract atualizarSetor(setorId: number, newSetor: Setor): Observable<Setor>;
  public abstract filtrarSetor(setorId: number): Observable<Setor>;
  public abstract deletarSetor(setorId: number): void;

  // public abstract carregaRegras(): Observable<Regra[]>;
  // public abstract criaRegra(regra: Regra): Observable<Regra>;
  // public abstract atualizarRegra(regraId: number, newRegra: Regra): Observable<Regra>;
  // public abstract filtrarRegra(regraId: number): Observable<Regra>;
  // public abstract deletarRegra(regraId: number): void;

  public abstract criaSolicitacaoTroca(solicitacao: RequestChange): Observable<RequestChange>;
  public abstract carregarSolicitacoesTroca(escalaId: number): Observable<RequestChange[]>;

  public abstract carregaItemColaborador(): Observable<itemColaborador>;
  public abstract atualizarItemColaborador(itemId: number, newItem: itemColaborador): Observable<itemColaborador>;
  public abstract criaItemColaborador(item: itemColaborador): Observable<itemColaborador>;
  public abstract deletarItemColaborador(itemId: number): void;

  public abstract deletarItensColaborador(itens: number[]): void;


  public abstract adicionarColaborador(colaboradorData: addColaborador): Observable<addColaborador>;

  public getTurnos(): Observable<Turno[]> {
    return this.scaleStore.getTurnos()
  }

  public getTurnosByEscala(escalaId: number): Observable<Turno[]> {
    return this.scaleStore.getTurnosByEscala(escalaId)
  }

  public getTurnoById(id: number): Observable<Turno> {
    return this.scaleStore.getTurnos().pipe(
      map((turnos) => {
        return turnos.find(turno => turno.id === id) || {} as Turno;
      })
    )
  }

  public getSetorById(id: number): Observable<Setor> {
    return this.scaleStore.getSetores().pipe(
      map((setor) => {
        return setor.find(setor => setor.id === id) || {} as Setor;
      })
    )
  }

  public getSetores(): Observable<Setor[]> {
    return this.scaleStore.getSetores()
  }

  public getSetoresByEscala(escalaId: number): Observable<Setor[]> {
    return this.scaleStore.getSetoresByEscala(escalaId)
  }

  getCoresSelecionadas(escalaId: number, filterSetores: boolean): Observable<string[]> {
    return this.scaleStore.getColorsSelectedByScaleId(escalaId, filterSetores);
  }
}
