import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  mouseOvered = false;

  isLogged$!: Observable<boolean>;

  constructor(private usuarioService: UsuarioService) {
    // this.sessao.restartSessao();
    // authStore.restart();
  }

  ngOnInit(): void {
    // this.sessao$ = this.sessao.getSessao();
    this.isLogged$ = this.usuarioService.isLogged();
  }
}
