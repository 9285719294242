import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { tap } from 'rxjs';
import { Colaborador } from 'src/app/models/colaborador';
import { ColaboradorService } from 'src/app/services/colaborador/colaborador.service';

@Component({
  selector: 'app-create-event-collaborators',
  templateUrl: './create-event-collaborators.component.html',
  styleUrls: ['./create-event-collaborators.component.scss'],
})
export class CreateEventCollaboratorsModalComponent  implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private colaboradorService: ColaboradorService
  ) { }

  @Output() sendNewEvent: EventEmitter<{ type: string, dtInicio: string, dtFim: string}> = new EventEmitter();

  dtInicio: string = this.formatarDataParaString();
  dtFim: string = this.formatarDataParaString();

  @Input() colaborador!: Colaborador;
  @Input() type!: 'FERIAS' | 'AFASTAMENTO_MEDICO';

 formatarDataParaString() {
    const options: any = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date().toLocaleDateString('pt-BR', options);
    const formateDate = date.split('/').reverse().join('-');
    return formateDate;
  }

  crateLeave() {
    this.colaboradorService.criarAfastamento(this.type, this.colaborador.id, this.dtInicio, this.dtFim)
    .pipe(
      tap((r) => {
        this.modalCtrl.dismiss({
          id: r.id,
          type: r.tipo,
          dtInicio: r.inicio,
          dtFim: r.fim
        })
      })
    ).subscribe()
  }

  getTitle(): string {
    return this.type == 'FERIAS' ? 'Férias' : 'Afastamento Médico'
  }

  ngOnInit() {}

  sendEvent() {
    this.crateLeave();
    // this.modalCtrl.dismiss({
    //   type: this.type,
    //   dtInicio: this.dtInicio.split('-').reverse().join('/'),
    //   dtFim: this.dtFim.split('-').reverse().join('/')
    // })
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
