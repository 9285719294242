<ion-content>
  <ion-toolbar color="tertiary">
    <ion-buttons slot="end">
      <button ion-button icon-only color='black'>
        <ion-icon name="close"></ion-icon>
      </button>
    </ion-buttons>
    <ion-title>Ionic Modal Title</ion-title>
  </ion-toolbar>
  <p>teste</p>
</ion-content>
