import { Injectable } from "@angular/core";
import { EscalaService } from "./escala.service";
import { BehaviorSubject, Observable, catchError, map, of, tap, throwError } from "rxjs";
import { Escala } from "src/app/models/escala";
import { Estatistica } from "src/app/models/estatistica";
import { HttpClient } from "@angular/common/http";
import { ScaleStore } from "../Scale.store";
import { EndPoint, getEndpoint } from "src/app/models/endpoint";
import { LoadingService } from "../loading.service";
import { AuthStore } from "../Auth.store";

@Injectable()
export class EscalaHttpService extends EscalaService {

  constructor(private http: HttpClient, scaleStore: ScaleStore, private loadingService: LoadingService, private authStore: AuthStore) {
    super(scaleStore);
  }

  private estatisticas: BehaviorSubject<Estatistica[]> = new BehaviorSubject<Estatistica[]>([]);
  public estatisticas$: Observable<Estatistica[]> = this.estatisticas.asObservable();

  public carregaEscalas(localId: number): Observable<Escala[]> {
    return this.http.get<Escala[]>(`${getEndpoint(EndPoint.ESCALA)}?localId=${localId}`).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return [];
      }),
      catchError((err: any) => {
        const message = "Erro ao carregar as escalas."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((response) => {
        if(response[0]) {
        this.scaleStore.setEscalas(response[0].local!, response)
        }
      }),
    );
  }

  public getEscalaById(id: number): Observable<Escala> {
    this.loadingService.presentLoading();
    return this.http.get<Escala[]>(`${getEndpoint(EndPoint.ESCALA)}/getById?id=${id}`).pipe(
      map((response) => {
        if (response) {
          return response[0];
        }
        return {} as Escala;
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading();
        const message = "Erro ao carrega a escala"
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.loadingService.dismissLoading();
        this.scaleStore.updateEscala(res)
      }),
    )
  }

  public criaEscala(escala: Escala): Observable<Escala> {
    this.loadingService.presentLoading();
    return this.http.post<Escala>(getEndpoint(EndPoint.ESCALA), {
      ...escala,
    }).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return {} as Escala;
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading();
        const message = "Erro ao criar uma escala."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.loadingService.dismissLoading();
        this.scaleStore.addEscala(res)
      }),
    )
  }

  public filtrarEscalaPorLocal(localId: number): Observable<Escala[]> {
    return this.http.get<Escala[]>(`${getEndpoint(EndPoint.ESCALA)}?localId=${localId}`).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return [];
      })
    );
  }

  public deletarEscala(escalaId: number): void {
    this.loadingService.presentLoading();
    this.http.delete(`${getEndpoint(EndPoint.ESCALA)}?id=${escalaId}`).pipe(
      catchError((err: any) => {
        this.loadingService.dismissLoading();
        const message = "Erro ao excluir um local."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap(() => this.loadingService.dismissLoading())
    ).subscribe()
  }

  public atualizarEscala(escalaId: number, changes: Partial<Escala>): Observable<Escala> {
    this.loadingService.presentLoading();
    return this.http.put<Escala>(getEndpoint(EndPoint.ESCALA), {
      ...changes,
      id: escalaId
    }).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return {} as Escala;
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading();
        const message = "Erro ao atualizar uma escala."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((r: any) => {
        this.scaleStore.updateEscala({
          ...changes,
          id: escalaId
        });
        this.loadingService.dismissLoading()
      })
    )
  }

  public carregaEstatisticas(): Observable<Estatistica[]> {
    console.log(this.estatisticas$)
    return this.estatisticas$;
  }

  public criaEstatistica(estatistica: Estatistica): Observable<Estatistica> {
    let estatisticas = this.estatisticas.getValue();
    if(estatisticas.length === 0){
      estatistica.id = 1;
    } else{
      estatistica.id = estatisticas[estatisticas.length -1].id + 1;

      //desativa dps
      // estatistica.idEscala = estatisticas[estatisticas.length -1].id + 1;
    }
    console.log(estatistica)
    estatisticas.push(estatistica)
    this.estatisticas.next(estatisticas);
    console.log(this.estatisticas)
    return of(estatistica);
  }


  public filtrarEstatistica(estatisticaId: number): Observable<Estatistica> {
    const estatisticas = this.estatisticas.getValue();
    const estatistica = estatisticas.find(estatistica => estatistica.id === estatisticaId)!

    return of(estatistica)
  }

  public override getEscalas(localId: number): Observable<Escala[]> {
    return this.scaleStore.escalas$.pipe(
      map(res => {
        if(res.find(escala => escala.local.id === localId)) {
          return res.find(escala => escala.local.id === localId)!.escalas
        }
        return []
      })
    )
  }

  public filtrarEstatisticaPorEscala(escalaId: number): Observable<Estatistica[]> {
    return this.estatisticas$.pipe(
      map(estatisticas => estatisticas.filter(estatistica => estatistica.idEscala === escalaId))
    )
  }

  public duplicarEscala(escala: Escala): Observable<Escala> {
    this.loadingService.presentLoading();
    return this.http.post<Escala>(getEndpoint(EndPoint.ESCALA), {
      ...escala,
      id: 0,
    }).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return {} as Escala;
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading();
        const message = "Erro ao duplicar a escala."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.loadingService.dismissLoading();
        this.scaleStore.addEscala(res)
      }),
    )
  }

  public override removerColaboradorEscala(escalaId: number, colaboradorId: number): Observable<any> {
    this.loadingService.presentLoading();

    return this.http.delete(`${getEndpoint(EndPoint.ESCALA)}/delete/colaborador?idEscala=${escalaId}&idColaborador=${colaboradorId}`).
    pipe(
      tap((_) => {
        this.loadingService.dismissLoading();
      }),
    )
  }

  public aplicarEmMassaEscala(turnoId: number, escalaId: number, items: { colaboradorId: number; date: string; setorId: number; }[]): Observable<any> {
    this.loadingService.presentLoading();

    const newList = []

    for(let item of items) {
      newList.push({
        turnoId,
        escalaId,
        items: [
          {
            ...item,
            date: new Date(item.date).toJSON()
          }
        ]
      })
    }

    const itemsMapped = items.map((r) => {
      r.date = new Date(r.date).toJSON()
      return r;
    })

    const body = [
      {
        turnoId,
        escalaId,
        items: itemsMapped
      }
    ]

    return this.http.post(`${getEndpoint(EndPoint.ESCALA)}/itens/saveList`, body).
      pipe(
        tap((r) => {
          console.log(r);
          this.loadingService.dismissLoading();
        }),
      )
    }

    public override sugerirRegra(sugestao: String): Observable<any> {
      return this.http.post(`${getEndpoint(EndPoint.ESCALA)}/regras/sugestao`, {
        sugestao,
        userId: this.authStore.getAdminId()
      })
    }

    public override carregaRegrasSugeridas(): Observable<any> {
      return this.http.get(`${getEndpoint(EndPoint.ESCALA)}/regras/sugestao?userId=${this.authStore.getAdminId()}`)
    }


}
