import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthStore } from "src/app/services/Auth.store";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authStore: AuthStore) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLogged = this.authStore.isLoggedLocal()

    if (isLogged) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.authStore.getAccessToken()!.trim()}`)
      });
    }

    return next.handle(req);
  }

}
