import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Troca } from "src/app/models/troca";
import { TrocaService } from "./troca.service";

@Injectable()
export class TrocaMockService extends TrocaService {

    private trocas: BehaviorSubject<Troca[]> = new BehaviorSubject<Troca[]>([]);

    public trocas$: Observable<Troca[]> = this.trocas.asObservable();

    constructor() {
        super();
    }

    public carregaTrocas(): Observable<Troca[]> {
      return this.trocas$;
    }

    public criaTroca(turno: Troca): Observable<Troca> {
      let trocas = this.trocas.getValue();
      if(trocas.length === 0){
          turno.id = 1;
      }
      else{
          turno.id = trocas[trocas.length -1].id + 1;
      }
      trocas.push(turno)
      this.trocas.next(trocas);
      console.log(this.trocas)
      return of(turno);
    }

    public filtrarTroca(turnoId: number): Observable<Troca> {
      const trocas = this.trocas.getValue();
      const turno = trocas.find(turno => turno.id === turnoId)!

      return of(turno)
    }

    public atualizarTroca(turnoId: number, changes: Partial<Troca>): Observable<Troca> {
      const trocas = this.trocas.getValue();
      const index = trocas.findIndex(turno => turno.id === turnoId);
      const newTroca: Troca = {
        ...trocas[index],
        ...changes
      }

      const newTrocas: Troca[] = trocas.slice(0);
      newTrocas[index] = newTroca;
      this.trocas.next(newTrocas);
      return of(newTroca)
    }

    public deletarTroca(turnoId: number): void {
      const trocas = this.trocas.getValue();
      const trocasFiltered = trocas.filter(turno => turno.id != turnoId);
      this.trocas.next(trocasFiltered)
    }

}
