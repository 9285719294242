import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Empresa } from 'src/app/models/empresa';
import { ScaleStore } from '../Scale.store';

export abstract class EmpresaService {

  protected scaleStore: ScaleStore;
  constructor(scaleStore: ScaleStore) {
    this.scaleStore = scaleStore;
  }

  protected handleError(error: any) {
    return throwError(error);
  }

  protected log(message: string) {
      console.log('============= message response ============');
      console.log(message);
  }

 public abstract carregaEmpresasByAdminId(adminId: number, ativo: boolean): Observable<Empresa[]>;
 public abstract criaEmpresa(empresa: Empresa): Observable<Empresa>;
 public abstract atualizarEmpresa(empresaId: number, empresaNew: Empresa): Observable<Empresa>;
 public abstract deletarEmpresa(empresaId: number): void;
 public abstract setEmpresaSelect(empresa: Empresa): void;

 public getEmpresas(): Observable<Empresa[]> {
  return this.scaleStore.empresas$;
 }

 getEmpresaSelect(): Observable<Empresa> {
  return this.scaleStore.empresaSelected$;
 }

 public addEmpresas(empresas: Empresa[]): void {
  console.log(empresas, 'add')
  this.scaleStore.addEmpresas(empresas);
 }

}
