import { Injectable } from "@angular/core";
import { EmpresaService } from "./empresa.service";
import { HttpClient } from "@angular/common/http";
import { Observable, catchError, map, tap, throwError } from "rxjs";
import { Empresa } from "src/app/models/empresa";
import { ScaleStore } from "../Scale.store";
import { EndPoint, getEndpoint } from "src/app/models/endpoint";
import { LoadingService } from "../loading.service";
import { AuthStore } from "../Auth.store";
import { UserAdmin } from "src/app/models/useradmin";

@Injectable()
export class EmpresaHttpService extends EmpresaService {


  constructor(
    private http: HttpClient,
    scaleStore: ScaleStore,
    private authStore: AuthStore,
    private loadingService: LoadingService
    ) {
    super(scaleStore);
  }

  public carregaEmpresasByAdminId(adminId: number, ativo: boolean): Observable<Empresa[]> {
    return this.http.get<Empresa[]>(`${getEndpoint(EndPoint.EMPRESA)}?adminUserId=${adminId}&ativo=${ativo}`).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return [];
      }),
      catchError((err: any) => {
        const message = "Erro ao carregar as empresas."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap(res => {
        if(!ativo) return;
        this.scaleStore.setEmpresas(res);
        this.scaleStore.setEmpresaSelected(res[0]);
      })
    );
  }

  public criaEmpresa(empresa: Empresa): Observable<Empresa> {
    this.loadingService.presentLoading();
    return this.http.post<Empresa>(getEndpoint(EndPoint.EMPRESA), {
      ...empresa,
      adminUser: this.authStore.getAdminId()
    }).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return {} as Empresa;
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading();
        const message = "Erro ao criar uma empresa."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.loadingService.dismissLoading();
        this.scaleStore.addEmpresa(res);
        if(!this.authStore.getIsLogged()) {
          this.setEmpresaSelect(res);
          // this.scaleStore.setEmpresaSelected(res);
        }
      })
    )
  }

  public atualizarEmpresa(empresaId: number, empresaNew: any): Observable<Empresa> {
    return this.http.put<Empresa>(getEndpoint(EndPoint.EMPRESA), {
      ...empresaNew,
      adminUser: empresaNew.adminUser.id,
      id: empresaId
    }).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return {} as Empresa;
      }),
      catchError((err: any) => {
        const message = "Erro ao atualizar uma empresas."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => this.scaleStore.updateEmpresa(res))
    )

  }

  public deletarEmpresa(empresaId: number): void {
    this.http.delete(`${getEndpoint(EndPoint.EMPRESA)}?id=${empresaId}`)
    .pipe(
      catchError((err: any) => {
        const message = "Erro ao excluir uma empresa"
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((_) => this.scaleStore.removeEmpresa(empresaId))
    )
    .subscribe()
  }

  public setEmpresaSelect(empresa: Empresa): void {
    this.scaleStore.setEmpresaSelected(empresa)
  }

}
