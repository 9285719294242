<ion-content class="ion-padding">
  <div class="card">
    <div class="container">
      <h2 class="title">404 Página Não Encontrada</h2>
      <p class="subtitle">A página não foi encontrada ou não existe.</p>

      <ion-button [routerLink]="['/']"> Página Inicial </ion-button>
    </div>
  </div>
</ion-content>
