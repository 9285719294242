import { Observable, throwError } from "rxjs";
import { Colaborador } from "src/app/models/colaborador";

export abstract class ColaboradorService {

    protected handleError(error: any) {
        return throwError(error);
    }

    protected log(message: string) {
        console.log('============= message response ============');
        console.log(message);
    }

    public abstract carregaColaboradores(empresaId: number): Observable<Colaborador[]>;
    public abstract criaColaborado(Colaborador: Colaborador): Observable<Colaborador>;
    public abstract atualizarColaborador(colaboradorId: number, newColaborador: Colaborador): Observable<Colaborador>;
    public abstract filtrarColaborador(colaboradorId: number): Observable<Colaborador>;
    public abstract carregaGestores(): Observable<Colaborador[]>;
    public abstract deletarColaborador(colaboradorId: number): void;
    public abstract getColaboradores(): Observable<Colaborador[]>;

    public abstract removerAfastamento(id: number): Observable<any>;
    public abstract criarAfastamento(tipo: string, colaborador: number,inicio: string, fim: string): Observable<any>;

}
