import { Component } from '@angular/core';
import { AuthStore } from './services/Auth.store';
import { LoginService } from './services/login/login.service';
import { ScaleStore } from './services/Scale.store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private authStore: AuthStore, private scaleStore: ScaleStore, private login: LoginService, private route: Router) {
    this.scaleStore.clearData();
  }

  ngOnInit(): void {
    if(this.authStore.isLoggedLocal()) {
      console.log(this.route.url, 'url')
      this.login.carregarEmpresas(this.authStore.getAdminId());
    } else {
      this.scaleStore.clearData();
      this.authStore.logout();
    }
  }
}
