<ion-content [fullscreen]="true">
  <div class="container" [formGroup]="formLogin">
    <div class="logo">
      <ion-img class="small" src="assets/logo-small.png"></ion-img>
      <ion-img aria-hidden="true" src="assets/logo.png"></ion-img>
    </div>
    <input type="email" placeholder="Email" name="email" formControlName="email">
    <input type="password" placeholder="Senha" formControlName="password">
    <button class="btn" [disabled]="!formLogin.valid" (click)="submit()">Entrar</button>
    <p>Nao possui conta? <a (click)="sendTo()">Cadastre-se</a></p>
    <p (click)="openModalForgot()" style="margin-bottom: 20px;">Esqueci minha senha</p>

    <p>Versão 1.4 - 05/02/2024</p>
  </div>
</ion-content>
