import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Empresa } from 'src/app/models/empresa';
import { EmpresaService } from 'src/app/services/empresa/empresa.service';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-select-empresa',
  templateUrl: './select-empresa.component.html',
  styleUrls: ['./select-empresa.component.scss'],
})
export class SelectEmpresaComponent  implements OnInit {

  formEmpresa!: FormGroup;
  empresas$!: Observable<Empresa[]>
  constructor(
    private login: LoginService,
    private empresa: EmpresaService, private route: Router,
    private modalCtrl: ModalController
    ) { }

  ngOnInit() {
    this.formEmpresa = new FormGroup({
      empresaId: new FormControl(0,[Validators.required]),
    });
    this.empresas$ = this.empresa.getEmpresas()
  }

  submit() {
    if(this.formEmpresa.invalid) return;
    this.modalCtrl.dismiss();

    // this.empresa.setEmpresaSelect();
    if(this.formEmpresa.value.empresaId == 0) return;
    this.login.setEmpresaSelect(this.formEmpresa.value.empresaId);
  }

  close() {
    this.modalCtrl.dismiss();
  }

  sendTo() {
    this.modalCtrl.dismiss();
    this.route.navigate(['empresa'])
  }


}
