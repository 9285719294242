import { Injectable } from "@angular/core";
import { Empresa } from "../models/empresa";
import { BehaviorSubject, Observable, map, of } from "rxjs";
import { Escala } from "../models/escala";
import { Local } from "../models/local";
import { Colaborador } from "../models/colaborador";
import { StorageKeys } from "../models/storage_keys";
import { Setor } from "../models/setor";
import { Turno } from "../models/turno";

type EscalaLocal ={
  local: Local,
  escalas: Escala[]
}

type escalaDetail = {
  escalaId: string,
  turnos: Turno[];
  setores: Setor[];
}
@Injectable({
  providedIn: 'root',
})

export class ScaleStore {

  private empresas = new BehaviorSubject<Empresa[]>([]);
  empresas$: Observable<Empresa[]> = this.empresas.asObservable()

  private escalas = new BehaviorSubject<EscalaLocal[]>([]);
  escalas$: Observable<EscalaLocal[]> = this.escalas.asObservable()

  private locais = new BehaviorSubject<Local[]>([]);
  locais$: Observable<Local[]> = this.locais.asObservable()

  private colaboradores = new BehaviorSubject<Colaborador[]>([]);
  colaboradores$: Observable<Colaborador[]> = this.colaboradores.asObservable()

  private empresaSelected = new BehaviorSubject<Empresa>({} as Empresa);
  empresaSelected$: Observable<Empresa> = this.empresaSelected.asObservable();

  private turnos =  new BehaviorSubject<Turno[]>([]);
  turnos$: Observable<Turno[]> = this.turnos.asObservable()

  private setores =  new BehaviorSubject<Setor[]>([]);
  setores$: Observable<Setor[]> = this.setores.asObservable()

  constructor() {}

  setLocais(locaisData: Local[]) {
    this.setData(locaisData, this.locais, StorageKeys.LOCALES_DATA)
  }

  setEmpresas(empresasData: Empresa[]) {
    this.setData(empresasData, this.empresas, StorageKeys.EMPRESAS_DATA)
  }

  setEscalas(local: Local,escalasData: Escala[]) {
    // this.setData(escalasData, this.escalas)
    const data = this.escalas.value;
    data.push({
      local: local,
      escalas: escalasData
    });
    console.log(data, 'setEscalas')
    this.escalas.next(data)
  }

  setColaboradores(colaboradoresData: Colaborador[]) {
    this.setData(colaboradoresData, this.colaboradores, StorageKeys.COLABORADORES_DATA)
  }

  setEmpresaSelected(empresaData: Empresa) {
    this.escalas.next([])
    this.empresaSelected.next({} as any)
    this.setData(empresaData, this.empresaSelected);
  }

  getEmpresaSelected(): Empresa {
    return this.getData(this.empresaSelected);
  }

  getEmpresas(): Empresa[] {
    return this.getData(this.empresas)
  }

  getLocais(): Local[] {
    return this.getData(this.locais)
  }

  getLocaisNotEmpty() {
    return this.escalas$.pipe(
      map(escalas => {
      const locais = escalas.filter(escala => escala.escalas.length != 0 && escala.local.ativo == true)
        if(locais) {
          return locais.map(escala => escala.local)
        }
        return []
      })
    )
  }


  getEscalas(LocalId: number): Escala[] {
    const dataFiltered = this.escalas.value.find(escala => escala.local.id === LocalId)
    const data = dataFiltered?.escalas!;
    return data;
  }

  getEscalaO(localId: number): Observable<Escala[]> {
    // return of(this.getEscalas(localId))
    return this.escalas$.pipe(
      map(res => {
        if(res) {
          return res.find(escala => escala.local.id === localId)!.escalas
        }
        return []
      })
    )
  }

  getLocaisAtivos() {
    return this.locais$.pipe(
      map((res) => {
        return res.filter(local => local.ativo === true);
      })
    )
  }



  getColaboradores(): Colaborador[] {
    return this.getData(this.colaboradores)
  }

  addColaborador(colaborador: Colaborador): void {
    this.addData(colaborador, this.colaboradores);
  }

  updateColaborador(colaborador: Colaborador): void {
    this.updateData(colaborador.id!, colaborador, this.colaboradores)
  }

  addTurno(turno: Turno): void {
    this.addData(turno, this.turnos)
  }

  addSetor(setor: Setor): void {
    this.addData(setor, this.setores)
  }

  updateSetor(setor: Setor): void {
    this.updateData(setor.id, setor, this.setores)
  }

  updateTurno(turno: Turno): void {
    this.updateData(turno.id, turno, this.turnos)
  }

  deleteTurno(turnoId: number): void {
    this.removeData(turnoId, this.turnos)
  }

  deleteSetor(setorId: number): void {
    this.removeData(setorId, this.setores)
  }

  setSetores(setores: Setor[]): void {
    this.addDatas(setores, this.setores);
  }

  setTurnos(turnos: Turno[]): void {
    this.addDatas(turnos, this.turnos);
  }

  getTurnosByEscala(escalaId: number): Observable<Turno[]> {
    return this.turnos$.pipe(map((res) => {
      return res.filter((turno) => turno.escalaId == escalaId)
    }))
  }

  getSetoresByEscala(escalaId: number): Observable<Setor[]> {
    return this.setores$.pipe(map((res) => {
      return res.filter((setor) => setor.escalaId == escalaId)
    }))
  }

  getSetores(): Observable<Setor[]> {
    return this.setores$;
  }

  getTurnos(): Observable<Turno[]> {
    return this.turnos$;
  }

  removeColaborador(colaboradorId: number): void {
    this.removeData(colaboradorId, this.colaboradores)
  }

  addEscala(newEscala: Escala): void {
    const localId = newEscala.local!.id;
    const index = this.escalas.value.findIndex((data: any) => data.local.id === localId)
    const newData = this.escalas.value;
    if(index == -1) {
      newData.push({
        local: newEscala.local!,
        escalas: [newEscala]
      })
      this.escalas.next(newData);
      return;
    }
    newData[index].escalas.push(newEscala);
    console.log(newData, index)
    this.escalas.next(newData);
  }

  updateEscala(newData: any): void {
    const localId = newData.localId;
    const indexEscala = this.escalas.value.findIndex((sl) => sl.local.id == localId)
    if(indexEscala != -1) {
      const arrayData = this.escalas.value;
      const escalas = arrayData[indexEscala].escalas;
      const escalaFiltered = escalas.filter(x => x.id != newData.id);
      const escala = this.escalas.value[indexEscala].escalas.find(x => x.id == newData.id)
      const newChanges = {
        ...escala,
        ...newData
      }

      escalaFiltered.push(newChanges)
      arrayData[indexEscala].escalas = escalaFiltered;
      this.escalas.next(arrayData);
      console.log(arrayData)
    }

    // const index = this.escalas.indexOf(localId);;
  }

  addEmpresa(empresa: Empresa): void {
    this.addData(empresa, this.empresas);
  }

  addEmpresas(empresas: Empresa[]): void {
    const datas = this.empresas.value;
    datas.concat(empresas)
    this.setData(datas, this.empresas, StorageKeys.EMPRESAS_DATA)
    // this.empresas.next(newDatas);
  }

  updateEmpresa(empresa: Empresa): void {
    this.updateData(empresa.id!, empresa, this.empresas)
    if(this.empresaSelected.value.id === empresa.id) {
      this.empresaSelected.next(empresa)
      console.log('set')
    }
  }

  addLocal(local: Local): void {
    this.addData(local, this.locais);
    const escalaData = this.escalas.value
    escalaData.push({
      local: local,
      escalas: []
    })
    this.escalas.next(escalaData)
  }


  updateLocal(local: Local): void {
    this.updateData(local.id!, local, this.locais)

    const localId = local.id;
    const data = this.escalas.value.find((data: any) => data.local.id === localId)
    const datas = this.escalas.value.filter((data: any) => data.local.id !== localId);

    const newData = {
      ...data!,
      local: local
    }
    datas.push(newData);
    // const newData = this.escalas.value;
    this.escalas.next(datas);
  }

  removeLocal(localId: number): void {
    this.removeData(localId, this.locais)
    const index = this.escalas.value.findIndex((data: any) => data.local.id === localId)
    const newData = this.escalas.value;
    newData.splice(index, 1);
    this.escalas.next(newData)
  }

  removeEmpresa(empresaId: number): void {
    this.removeData(empresaId, this.empresas)
  }

  private setData(data: any, subject: BehaviorSubject<any>, storageName?: string) {
    subject.next(data)
    if(storageName) {
      localStorage.setItem(storageName, JSON.stringify(data))
    }
    console.log(data, 'setData');
  }

  private addDatas(data: any, subject: BehaviorSubject<any>, storageName?: string) {
    const oldData = subject.value
    const newData = [...oldData, ...data]
    subject.next(newData)
    if(storageName) {
      localStorage.setItem(storageName, JSON.stringify(data))
    }
    console.log(data, 'addDatas');
  }

  private getData(subject: BehaviorSubject<any>): any {
    const value = subject.value;
    console.log(value, 'getData')
    return value;
  }

  private updateData(dataId: number, newData: any, subject: BehaviorSubject<any>) {
    const subjectValue = subject.getValue();
    const index = subjectValue.findIndex((data: any) => data.id === dataId);
    const newData_: any = {
      ...newData
    }

    const datas: any[] = subjectValue.slice(0);
    datas[index] = newData_;
    subject.next(datas);
  }

  private addData(newData: any, subject: BehaviorSubject<any>) {
    const data = subject.value;
    data.push(newData);
    subject.next(data)
  }

  containsSetor(setorId: number): boolean {
    const find = this.setores.value.find((res) => res.id === setorId)
    if(find) {
      return true;
    } else {
      return false
    }
  }

  getColorsSelectedByScaleId(escalaId: number, sector: boolean) {
    const list = sector ?
    this.setores.value.filter(o => o.escalaId === escalaId) : this.turnos.value.filter(o => o.escalaId === escalaId);
    const cores = list.map(c => c.cor);
    return of(cores);
  }


  private removeData(dataId: any, subject: BehaviorSubject<any>) {
    const data = subject.value;
    console.log(data, dataId)
    const newData = data.filter((dataRes: any) => dataRes.id != dataId)
    subject.next(newData)
  }

  clearData() {
    localStorage.removeItem(StorageKeys.LOCALES_DATA)
    localStorage.removeItem(StorageKeys.EMPRESAS_DATA)
    localStorage.removeItem(StorageKeys.ESCALAS_DATA)
    localStorage.removeItem(StorageKeys.COLABORADORES_DATA)
  }

}
