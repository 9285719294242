import { Component } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {

  constructor(private login: LoginService) {}

  public appPages = [
    { title: 'Escalas', url: '/scale', icon: 'apps' },
    { title: 'Colaboradores', url: '/collaborators', icon: 'people' },
    { title: 'Locais', url: '/locations', icon: 'Location' },
    { title: 'Empresas', url: '/empresa', icon: 'business' },
  ];

  logout() {
    this.login.logout();
  }
}
