import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Observable, tap } from 'rxjs';
import { getColor, getColors, getCorPorValor } from 'src/app/models/colors';
import { TurnoService } from 'src/app/services/turno/turno.service';

@Component({
  selector: 'app-popover-select-color',
  templateUrl: './popover-select-color.component.html',
  styleUrls: ['./popover-select-color.component.scss'],
})
export class PopoverSelectColorComponent  implements OnInit {

  constructor(
    private popoverCtrl: PopoverController,
    private turnoService: TurnoService
  ) { }

  colorsSelected$!: Observable<string[]>;

  @Input() isSector!: boolean;
  @Input() scaleId!: number;

  colors: string[] = [];

  ngOnInit() {
    this.colorsSelected$ = this.turnoService.getCoresSelecionadas(this.scaleId, this.isSector).pipe(
      tap((r) => {
        this.colors = r;
      })
    )
  }

  getAllColors() {
    return getColors().filter(col => !this.isSelected(col));
  }

  isSelected(color: string) {
    return this.colors.includes(getCorPorValor(color));
  }

  setColor(name: string, isSelected: boolean) {
    if(isSelected) return;
    const color = getCorPorValor(name);
    this.popoverCtrl.dismiss(color);
  }

}
