<label class="toggle-switch">
  <input
    type="checkbox"
    [(ngModel)]="checked"
    (ngModelChange)="emitEvent($event)"
    [disabled]="disabledExpression"
  />
  <div class="toggle-switch-background">
    <div class="toggle-switch-handle"></div>
  </div>
</label>
