  <ion-content>

  <div class="container">
  <ion-icon
    class="close-icon"
    name="close-outline"
    (click)="close()"
  ></ion-icon>

  <ng-container *ngIf="(scaleInUse$ | async) as scaleUse">
    <header>
      <div class="scale-detail">
        <h2>{{ scaleUse.escala!.local!.nome }}</h2>
        <h1>{{ scaleUse.escala!.nome }}</h1>
        <p>Gestor: {{ scaleUse.colaborador!.nome }}</p>
      </div>
      <div class="scale-date">
        <h3>{{ scaleUse.date | date: 'MMMM' }} {{ scaleUse.date | date: 'Y' }} </h3>
        <p>Daiane Barros, Ferias de 6 a 30/01/2023</p>
        <p>Ana Cristina, LM de 13 a 19/01/2023</p>
      </div>
      <div class="scale-acronym">
        <div class="col 1">
          <div class="item" *ngFor="let acronym of acronyms1">
            <h5>{{ acronym.symbol }}</h5>
            <p>{{ acronym.name }}</p>
          </div>
        </div>

        <div class="col 2">
          <div class="item" *ngFor="let acronym of acronyms2">
            <h5>{{ acronym.symbol }}</h5>
            <p>{{ acronym.name }}</p>
          </div>
        </div>
      </div>
    </header>

      <!-- <table>
        <thead>
          <th class="title-head" colspan="3">UTI GERAL</th>
          <th class="ch">CH</th>
          <th *ngFor="let item of items" class="item">
            <p class="title-item">{{ item.title }}</p>
            <p class="day">{{ item.day }}</p>
          </th>
        </thead>
        <tbody>
          <tr *ngFor="let item of tableData">
            <td colspan="3" class="name">{{ item.name }}</td>
            <td class="ch"> {{ item.ch }}</td>
            <td *ngFor="let data of item.data" class="item"> {{ data }}
              <div class="active"></div>
            </td>
          </tr>
        </tbody>
      </table> -->
      <div *ngIf="turnos">
        <table-export-pdf *ngFor="let item of persons" [shifts]="turnos" [tableItems]="listDay" [data]="item"></table-export-pdf>
      </div>

      <footer>
        <div class="coordination">
          coordenação de Fisioterapia
        </div>
        <div class="director">
          director(a)
        </div>
        <div class="contact">
            <img src="assets/troca.png" alt="Troca Logo">
            <p class="about">Escala gerada pelo aplicativo Trocaturno</p>
            <a class="link" href="https://trocaturno.com.br" target="_blank">trocaturno.com.br</a>
        </div>
      </footer>
  </ng-container>

  </div>
  </ion-content>
