import { Injectable } from "@angular/core";
import { AdminService } from "./admin.service";
import { Observable, catchError, map, tap, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UserAdmin } from "src/app/models/useradmin";
import { AuthStore } from "../Auth.store";
import { EndPoint, getEndpoint } from "src/app/models/endpoint";
import { LoadingService } from "../loading.service";

@Injectable()
export class AdminHttpService extends AdminService {

  constructor(private http: HttpClient, authStore: AuthStore, private loadingService: LoadingService) {
    super(authStore);
  }

  public carregarAdmin(adminId: number): Observable<UserAdmin> {
    return this.http.get<UserAdmin>(`${getEndpoint(EndPoint.ADMINUSER)}?adminId=${adminId}`).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return {} as UserAdmin;
      })
    );
  }

  public criaAdmin(UserAdmin: UserAdmin): Observable<UserAdmin> {
    this.loadingService.presentLoading();
    return this.http.post<UserAdmin>(getEndpoint(EndPoint.ADMINUSER), {
      ...UserAdmin,
    }).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return {} as UserAdmin;
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading()
        const message = "Erro ao criar um userAdmin"
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.loadingService.dismissLoading()
        this.authStore.updateUser({
          userId: res.id
        })
        this.authStore.setUserAdmin(res);
      })
    )
  }

  public atualizarAdmin(UserAdminId: number, newUserAdmin: UserAdmin): Observable<UserAdmin> {
    return this.http.put<UserAdmin>(getEndpoint(EndPoint.ADMINUSER), {
      ...newUserAdmin,
      id: UserAdminId
    }).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return {} as UserAdmin;
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading()
        const message = "Erro ao atualizar o userAdmin"
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.authStore.setUserAdmin(res)
        this.loadingService.presentToast('Dados alterados com sucesso!')
      })
    )
  }

  public deletarAdmin(UserAdminId: number): void {
    this.http.delete(`${getEndpoint(EndPoint.ADMINUSER)}?id=${UserAdminId}`)
  }

  public alterarSenha(currentPassword: string, newPassword: string): Observable<any> {
    return this.http.post<UserAdmin>(`${getEndpoint(EndPoint.ADMINUSER)}/alterarSenha`, {
      newPassword,
      password: currentPassword,
      username: this.authStore.getUserAdmin().email
    }).pipe(
      map((res) => {
        return res;
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading()
        const message = "Senha incompativel."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((_) => {
        this.loadingService.presentToast('Senha alterada com sucesso!')
      })
    )
  }

  public override recuperarSenha(email: string): Observable<any> {
    return this.http.post(`${getEndpoint(EndPoint.RECUPERAR_SENHA)}?email=${email}`, { })
  }


}
