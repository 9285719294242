import { Observable, throwError } from "rxjs";
import { Jornada } from "src/app/models/jornada";

export abstract class JornadaService {

    protected handleError(error: any) {
        return throwError(error);
    }

    protected log(message: string) {
        console.log('============= message response ============');
        console.log(message);
    }

    public abstract carregaJornadas(): Observable<Jornada[]>;
    public abstract criaJornada(jornada: Jornada): Observable<Jornada>;
    public abstract atualizarJornada(jornadaId: number, changes: Partial<Jornada>): Observable<Jornada>;
    public abstract filtrarJornada(jornadaId: number): Observable<Jornada>;
    public abstract deletarJornada(jornadaId: number): void;

}
