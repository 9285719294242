import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Setor } from 'src/app/models/setor';
import { ScaleInUseService } from 'src/app/services/scale-in-use.service';
import { TurnoService } from 'src/app/services/turno/turno.service';

@Component({
  selector: 'app-create-setor',
  templateUrl: './create-setor.component.html',
  styleUrls: ['./create-setor.component.scss'],
})
export class CreateSetorComponent  implements OnInit {

  isInput: boolean = false;

  showInput() {
    this.isInput = true;
  }

  constructor(
    private modalCtrl: ModalController,
    private TurnoService: TurnoService,
    private scaleInUse: ScaleInUseService
  ) { }

  Setores$!: Observable<Setor[]>

  ngOnInit() {
    if(this.scaleInUse.getEscalaId()) {
      this.Setores$ = this.TurnoService.getSetoresByEscala(this.scaleInUse.getEscalaId()!);
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
