import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teste-component',
  templateUrl: './teste-component.component.html',
  styleUrls: ['./teste-component.component.scss'],
})
export class TesteComponentComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
