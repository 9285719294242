import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalService } from "./local.service";
import { Local } from "../../models/local";
import { environment } from "src/environments/environment";
import { Observable, catchError, map, of, shareReplay, tap, throwError } from "rxjs";
import { AuthStore } from "../Auth.store";
import { ScaleStore } from "../Scale.store";
import { EndPoint, getEndpoint } from "src/app/models/endpoint";
import { LoadingService } from "../loading.service";

@Injectable()
export class LocalHttpService extends LocalService {


    constructor(private http: HttpClient, private authStore: AuthStore, scaleStore: ScaleStore, private loadingService: LoadingService ) {
      super(scaleStore)
    }

    public carregaLocais(empresaId: number): Observable<Local[]> {
        return this.http.get<Local[]>(`${getEndpoint(EndPoint.LOCAL)}?empresa=${empresaId}`).pipe(
          map((response) => {
            if (response) {
              return response;
            }
            return [];
          }),
          catchError((err: any) => {
            const message = "Erro ao carregar os locais."
            this.loadingService.presentToast(message)
            return throwError(err);
          }),
          tap((res) => {
            this.scaleStore.setLocais(res)
          })
        );
    }

    public criaLocal(local: Local): Observable<Local>{
      if(!this.scaleStore.getEmpresaSelected()) return of();
      this.loadingService.presentLoading();
        return this.http.post<Local>(getEndpoint(EndPoint.LOCAL), {
          ...local,
          empresa: this.scaleStore.getEmpresaSelected().id
        }).pipe(
          map((response) => {
            if (response) {
              return response;
            }
            return {} as Local;
          }),
          catchError((err: any) => {
            this.loadingService.dismissLoading();
            const message = "Erro ao criar um local."
            this.loadingService.presentToast(message)
            return throwError(err);
          }),
          tap((res) => {
            this.loadingService.dismissLoading();
            this.scaleStore.addLocal(res)
          })
        )
    }

    public atualizarLocal(localId: number, local: Local): Observable<Local> {
      this.loadingService.presentLoading();
      return this.http.put<Local>(getEndpoint(EndPoint.LOCAL), {
        ...local,
        id: localId,
        empresa: this.scaleStore.getEmpresaSelected().id
      }).pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return {} as Local;
        }),
        catchError((err: any) => {
          this.loadingService.dismissLoading();
          const message = "Erro ao atualizar um local."
          this.loadingService.presentToast(message)
          return throwError(err);
        }),
        tap(res => {
          this.loadingService.dismissLoading();
          this.scaleStore.updateLocal(res)
        })
      )
    }

    public deletarLocal(localId: number): void {
      this.loadingService.presentLoading();
      this.http.delete<string>(`${getEndpoint(EndPoint.LOCAL)}?id=${localId}`)
      .pipe(
        catchError((err: any) => {
          this.loadingService.dismissLoading();
          const message = "Erro ao excluir um local."
          this.loadingService.presentToast(message)
          return throwError(err);
        }),
        tap((_) => {
          this.loadingService.dismissLoading();
          this.scaleStore.removeLocal(localId)
        }),
      ).subscribe()
    }

}
