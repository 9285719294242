import { Observable, map, throwError } from "rxjs";
import { Local } from "../../models/local";
import { ScaleStore } from "../Scale.store";

export abstract class LocalService {

    protected scaleStore: ScaleStore;
    constructor(scaleStore: ScaleStore) {
      this.scaleStore = scaleStore;
    }

    protected handleError(error: any) {
        return throwError(error);
    }

    protected log(message: string) {
        console.log('============= message response ============');
        console.log(message);
    }

    public abstract carregaLocais(empresaId: number): Observable<Local[]>;

    public abstract criaLocal(local: Local): Observable<Local>;
    public abstract atualizarLocal(localId: number, local: Local): Observable<Local>;
    public abstract deletarLocal(localId: number): void;

    // public abstract getLocais(): Observable<Local[]>;

    public getLocais(): Observable<Local[]> {
      return this.scaleStore.locais$;
    }


    public getLocaisFiltered() {
      return this.scaleStore.getLocaisNotEmpty()
    }

    public getLocaisAtivos(): Observable<Local[]> {
      return this.scaleStore.locais$.pipe(
        map((res) => {
          return res.filter(local => local.ativo === true);
        })
      )
    }

}
