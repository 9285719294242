import { Observable, throwError } from "rxjs";
import { Troca } from "src/app/models/troca";

export abstract class TrocaService {

    protected handleError(error: any) {
        return throwError(error);
    }

    protected log(message: string) {
        console.log('============= message response ============');
        console.log(message);
    }

    public abstract carregaTrocas(): Observable<Troca[]>;
    public abstract criaTroca(troca: Troca): Observable<Troca>;
    public abstract atualizarTroca(trocaId: number, changes: Partial<Troca>): Observable<Troca>;
    public abstract filtrarTroca(trocaId: number): Observable<Troca>;
    public abstract deletarTroca(trocaId: number): void;

}
