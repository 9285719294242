import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { tap } from 'rxjs';
import { AdminService } from 'src/app/services/admin/admin.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordModalComponent  implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private loadingService: LoadingService,
    private adminService: AdminService
  ) { }

  email: string = '';

  ngOnInit() {}

  async sendRequest() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(!emailRegex.test(this.email)) {
      this.loadingService.presentToast('Informe um email valido.')
      return;
    }
    this.adminService.recuperarSenha(this.email).pipe(
      tap((r) => {
        console.log(r);
      })
    ).subscribe()
    await this.modalCtrl.dismiss();
    // TODO: colocar service
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
