import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent  implements OnInit {

  constructor() { }
  @Input() title!: string;
  @Input() btnName!: string;
  @Output() clickBtn: EventEmitter<void> = new EventEmitter();

  ngOnInit() {}

  clickEmit() {
    this.clickBtn.emit()
  }

}
