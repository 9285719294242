import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, shareReplay, tap } from 'rxjs';
import { Usuario } from '../models/usuario';
import { UserAdmin } from '../models/useradmin';
import { StorageKeys } from '../models/storage_keys';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root',
})
export class AuthStore {

  private user = new BehaviorSubject<Usuario>({} as Usuario);
  user$: Observable<Usuario> = this.user.asObservable();

  private userAdmin = new BehaviorSubject<UserAdmin>({} as UserAdmin);
  userAdmin$: Observable<UserAdmin> = this.userAdmin.asObservable();

  private isLogged_ = new BehaviorSubject<boolean>(false);
  isLogged$: Observable<boolean> = this.isLogged_.asObservable();

  constructor() {
    this.restart();
    console.log(this.isLogged_.value);
  }

  setUserData(data: any) {
    localStorage.setItem(StorageKeys.TOKEN, data.accessToken);
    localStorage.setItem(StorageKeys.USERDATA, JSON.stringify(data));
    this.user.next(data);
    // this.isLogged.next(true);
  }

  setUserAdmin(data: any) {
    localStorage.setItem(StorageKeys.USERADMIN, JSON.stringify(data));
    this.userAdmin.next(data);
  }

  getUser(): Usuario {
    return this.user.value;
  }

  getUserAdmin(): UserAdmin {
    return this.userAdmin.value;
  }

  getAdminId(): number {
    return this.user.value.userId;
  }

  getAccessToken() {
    const token = localStorage.getItem(StorageKeys.TOKEN) || ''
    return token;
  }

  setIsLogged(logged: boolean) {
    this.isLogged_.next(logged);
  }

  getIsLogged(): boolean {
    return this.isLogged_.value
  }

  isLoggedLocal() {
    return this.isTokenValid()
  }

  logout() {
    localStorage.removeItem(StorageKeys.USERDATA);
    localStorage.removeItem(StorageKeys.TOKEN);
    localStorage.removeItem(StorageKeys.USERADMIN);
    this.isLogged_.next(false);
    this.user.next({} as Usuario);
  }

  updateUser(changes: Partial<Usuario>) {
    const data = this.user.value;
    const newData = {
      ...data,
      ...changes
    }
    this.setUserData(newData);
  }

  updateUserAdmin(changes: Partial<UserAdmin>) {
    const data = this.user.value;
    const newData = {
      ...data,
      ...changes
    }
    this.setUserAdmin(newData)
  }

  restart() {
    const user = localStorage.getItem(StorageKeys.USERDATA);
    if (user) {
      this.isLogged_.next(true);
      this.user.next(JSON.parse(user));
    }
  }

  private decodeToken(jwtToken: string) {
    try {
      return jwt_decode(jwtToken) as any;
    } catch (e) {
      console.error(`erro ao decodificar o token ${jwtToken}`)
      return null;
    }
  }

  private isTokenValid(): boolean {
    const jwtToken = localStorage.getItem(StorageKeys.TOKEN)
    if (jwtToken) {
      const decodedToken = this.decodeToken(jwtToken);
      return decodedToken?.exp && new Date(decodedToken.exp * 1000).getTime() > new Date().getTime()
    }
    return false
  }

}
