import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { emit } from 'process';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent  implements OnInit {

  @Input() checked!: boolean;
  @Output() checkedEmit: EventEmitter<boolean> = new EventEmitter();
  @Input() disabledExpression: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  emitEvent($event: any) {
    this.checkedEmit.emit($event);
  }

}
