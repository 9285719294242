export function calculeHoursShift(h1: string, h2: string) {
  if (h1 == h2) return '24h';  // Verificação se h1 e h2 são iguais
  if(h1  == undefined || h2 == undefined || h1  == '' || h2 == '') return '0h';
  // console.log(h1, 'horas')
  const inicioHora = Number(h1.toString().split(':')[0])
  const inicioMinutos = Number(h1.toString().split(':')[1])
  const fimHora = Number(h2.toString().split(':')[0])
  const fimMinutos = Number(h2.toString().split(':')[1])


  const minutosInicio = inicioHora * 60 + inicioMinutos;
  const minutosFim = fimHora * 60 + fimMinutos;

  // Calcular a diferença de minutos
  let diferencaMinutos = minutosFim - minutosInicio;

  // Se a diferença for negativa, adicionar ou subtrair 24 horas (em minutos)
  if (diferencaMinutos < 0) {
    diferencaMinutos += 24 * 60;
  } else if (diferencaMinutos >= 24 * 60) {
    diferencaMinutos -= 24 * 60;
  }

  // Calcular o número de horas e minutos
  const horas = Math.floor(diferencaMinutos / 60);
  let minutos: any = diferencaMinutos % 60;

  if(minutos.toString().length == 1) {
    minutos = '0' + minutos;
  }


  return `${horas}h${minutos}`;
}
