<ion-toolbar>
  <ion-buttons slot="end">
    <ion-button (click)="close()"
      ><ion-icon name="close-outline"></ion-icon
    ></ion-button>
  </ion-buttons>
</ion-toolbar>
<ion-content class="ion-padding">
  <div class="container">
    <h1>{{ getTitle() }}</h1>

    <div class="dates">
      <label>
        Data Inicio
        <ion-datetime-button datetime="inicio"></ion-datetime-button>
      </label>
      <label>
        Data Fim
        <ion-datetime-button datetime="fim"></ion-datetime-button>
      </label>
    </div>
    <button class="btn" (click)="sendEvent()">Adicionar</button>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <h3>Inicio</h3>
        <ion-datetime  [(ngModel)]="dtInicio" id="inicio"  presentation="date" locale="pt-BR"></ion-datetime>
      </ng-template>
    </ion-modal>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <h3>Fim</h3>
        <ion-datetime [(ngModel)]="dtFim"  id="fim" presentation="date" locale="pt-BR"></ion-datetime>
      </ng-template>
    </ion-modal>
  </div>
</ion-content>
