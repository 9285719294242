<ion-toolbar>
  <ion-buttons slot="end">
    <ion-button (click)="close()"
      ><ion-icon name="close-outline"></ion-icon
    ></ion-button>
  </ion-buttons>
</ion-toolbar>
<ion-content [fullscreen]="true" *ngIf="empresas$ | async as empresas" >
  <div class="container" [formGroup]="formEmpresa">
    <div class="logo">
      <ion-img class="small" src="assets/logo-small.png"></ion-img>
      <ion-img aria-hidden="true" src="assets/logo.png"></ion-img>
    </div>
    <h1>Escolha uma empresa</h1>
    <!-- <select formControlName="empresaId">
      <option *ngFor="let empresa of empresas" value="{{ empresa.id }}">
        {{ empresa.nome }}
      </option>
    </select> -->
    <ion-select aria-label="Empresa"
      label-placement="floating"
      formControlName="empresaId"
      interface="popover"
      placeholder="Selecione uma empresa"
      [mode]="'ios'">
      <ion-select-option
      *ngFor="let empresa of empresas"
      value="{{ empresa.id }}">
        {{ empresa.nome }}
      </ion-select-option>
    </ion-select>
    <button class="btn" (click)="submit()">Selecionar</button>
    <p>Deseja criar outra empresa? <a (click)="sendTo()">Clique Aqui</a></p>
  </div>
</ion-content>
