import { Observable, throwError } from "rxjs";
import { Usuario } from "src/app/models/usuario";

export abstract class UsuarioService {

  protected handleError(error: any) {
      return throwError(error);
  }

  protected log(message: string) {
      console.log('============= message response ============');
      console.log(message);
  }

  public abstract login(email: string, password: string): Observable<Usuario>;
  public abstract signup(email: string, password: string): Observable<Usuario>;
  public abstract getUser(): Observable<Usuario>;
  public abstract logout(): void;
  public abstract setLogged(logged: boolean): void;
  public abstract isLogged(): Observable<boolean>;

}
