import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/views/page-not-found/page-not-found.component';
import { AuthGuard } from './modules/guards/AuthGuard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'scale',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./modules/pages.module').then(m => m.PagesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'empresa',
    loadChildren: () => import('./modules/empresa/empresa.module').then(m => m.EmpresaPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/auth/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: '**',
    title: 'Troca Turno | Não Encontrado',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
