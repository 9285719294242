
<div class="item" *ngIf="!isInput; else input">
  <div class="shift">
    <div class="color" [ngStyle]="{'background-color': colorTurno}"></div>
    <p type="text" class="shift-date">{{ h1 }}</p>
    <span>às</span>
    <p type="text" class="shift-date">{{ h2 }}</p>
    <p class="shift-name" >{{ turno.descricao }}</p>
    <p class="shift-abbreviation" >{{ turno.sigla }}</p>
    <p class="total-view">{{ calcShow() }}</p>
    <div class="checkbox">
      <label class="container">
        <input type="checkbox" [(ngModel)]="active" disabled />
        <div class="checkmark"></div>
      </label>
      <span>Ativo</span>
    </div>
    <div class="actions">
      <ion-icon name="create-outline" (click)="setEditMode()"></ion-icon>
      <ion-icon name="trash-outline" (click)="deleteTurno()"></ion-icon>
    </div>
  </div>
</div>

<ng-template #input>
      <div class="shift" [formGroup]="formGroup">
        <div class="color" [ngStyle]="{'background-color': colorSelected}" (click)="openPopover($event)"></div>
        <input type="time" class="shift-date" formControlName="horaInicio">
        <span>às</span>
        <input type="time" class="shift-date" formControlName="horaFim">
        <input class="shift-name" type="text" formControlName="descricao" />
        <input class="shift-abbreviation" type="text" formControlName="sigla" />
        <div class="total">{{ calcInput() }}</div>
        <div class="checkbox">
          <label class="container">
            <input type="checkbox" formControlName="ativo" />
            <div class="checkmark"></div>
          </label>
          <span>Ativo</span>
        </div>
        <div class="actions">
          <ion-icon name="checkmark-outline" (click)="submit()"></ion-icon>
          <ion-icon name="close-outline" (click)="cancel()"></ion-icon>
        </div>
      </div>
</ng-template>
