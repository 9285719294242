import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { tap } from 'rxjs';
import { EscalaService } from 'src/app/services/escala/escala.service';

@Component({
  selector: 'app-suggest-rule',
  templateUrl: './suggest-rule.component.html',
  styleUrls: ['./suggest-rule.component.scss'],
})
export class SuggestRuleModalComponent  implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private escalaService: EscalaService
  ) { }

  suggest: string = '';

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }

  suggestSubmit() {
    if(this.suggest.trim().length == 0) return;
    this.escalaService.sugerirRegra(this.suggest).pipe(
      tap((r) => {
        this.modalCtrl.dismiss({
          suggest: this.suggest
        })
      })
    ).subscribe()
  }

}
