import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import pt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from '../app-routing.module';
import { LayoutComponent } from './components/layout/layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { IonicModule } from '@ionic/angular';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { FormsModule } from '@angular/forms';
import { SharedPageModule } from '../shared/shared.module';
import { LoginPageModule } from '../modules/auth/login/login.module';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';

registerLocaleData(pt);

const maskConfig: Partial<IConfig> = {
  validation: true,
};

@NgModule({
  declarations: [
    PageNotFoundComponent,
    SidebarComponent,
    LayoutComponent,
    LoadingScreenComponent
  ],
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    BrowserModule,
    SharedPageModule,
    HttpClientModule,
    AppRoutingModule,
    LoginPageModule,
    IonicModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
  ],
  exports: [LayoutComponent],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
})
export class CoreModule {}
