import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Turno } from 'src/app/models/turno';
import { ScaleInUseService } from 'src/app/services/scale-in-use.service';
import { TurnoService } from 'src/app/services/turno/turno.service';

@Component({
  selector: 'app-create-turno',
  templateUrl: './create-turno.component.html',
  styleUrls: ['./create-turno.component.scss'],
})
export class CreateTurnoComponent  implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private turnoService: TurnoService,
    private scaleInUse: ScaleInUseService
  ) { }
  turnos$!: Observable<Turno[]>

  isInput: boolean = false;

  showInput() {
    this.isInput = true;
  }

  ngOnInit() {
    if(this.scaleInUse.getEscalaId()) {
      this.turnos$ = this.turnoService.getTurnosByEscala(this.scaleInUse.getEscalaId()!)
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
