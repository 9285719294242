<table *ngIf="data || tableItems">
  <thead [ngStyle]="{'background-color': headColor}">
    <th class="title-head" colspan="3">{{ titleTable | uppercase }}</th>
    <th class="ch">CH</th>
    <th *ngFor="let item of tableItems" class="item">
      <p class="title-item">{{ item.dayName }}</p>
      <p class="day">{{ item.day }}</p>
    </th>
  </thead>
  <tbody>
    <tr *ngFor="let item of data.data">
      <td colspan="3" class="name">{{ item.name }}</td>
      <td class="ch">{{ item.ch }}</td>
      <td *ngFor="let data of item.items" class="item"
      >
      {{ data.item ? getTurno(data.item.turnoId).sigla : '' }}
        <!-- <div class="active" *ngIf="isPoint(data)":></div> -->
      </td>
    </tr>
  </tbody>
</table>
