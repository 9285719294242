import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { Escala } from 'src/app/models/escala';
import { ScaleInUse } from 'src/app/models/scale-in-use';
import { ScaleInUseService } from 'src/app/services/scale-in-use.service';
import { TurnoService } from 'src/app/services/turno/turno.service';
import { calculeHoursShift } from '../../utils';
import { ModalController } from '@ionic/angular';
import { Turno } from 'src/app/models/turno';

@Component({
  selector: 'shared-export-pdf',
  templateUrl: './export-pdf.component.html',
  styleUrls: ['./export-pdf.component.scss'],
})
export class ExportPdfComponent  implements OnInit, OnDestroy {

  constructor(
    private scaleInUse: ScaleInUseService,
    private turnoService: TurnoService,
    private modalCtrl: ModalController
  ) { }

  listDay: any[] = [];

  scaleInUse$!: Observable<ScaleInUse | null>;
  scale!: Escala;

  persons: any[] = [];

  turnos: Turno[] = [];
  notifier = new Subject();

  async ngOnInit() {
    this.scaleInUse$ = this.scaleInUse.getObservable().pipe(
      takeUntil(this.notifier),
      tap((r) => {
        if(r == null) return;
        this.loadTurnos(r.escala!.id);
        this.scale = r.escala!;
        this.listDay = this.createDaysArray(r.date!);
        for(let setor of r.escala!.setores!) {
          this.loadPerson(setor.id ,setor.colaboradores);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.notifier.next(1)
    this.notifier.complete()
  }


  loadTurnos(scaleId: number): void {
    this.turnoService.getTurnosByEscala(scaleId).pipe(
      tap((r) => {
        this.turnos = r;
        r.forEach(r => {
          this.acronyms1.push({
            symbol: r.sigla,
            name: `${r.descricao} (${calculeHoursShift(r.horaInicio, r.horaFim)})`
          })
        })
      })
    ).subscribe()
  }

  acronyms1: { symbol: string, name: string }[] = [
    // { symbol: 'M', name: 'Manha (6 horas)' },
    // { symbol: 'T', name: 'Tarde (6 horas)' },
    // { symbol: 'MT', name: 'Manhã e Tarde (8 horas)' },
    // { symbol: 'SD', name: 'Serviço Diurno (12 horas)' },
    // { symbol: 'SN', name: 'Serviço Noturno (12 horas)' },
    // { symbol: 'FER', name: 'Feriado' },
  ]

  acronyms2: {symbol: string, name: string }[] = [
    { symbol: 'DC', name: 'Data Compensada' },
    { symbol: 'LP', name: 'Licença Prêmio'},
    { symbol: 'LM', name: 'Licença Médica'},
    { symbol: 'LG', name: 'Licença Gestão'},
    { symbol: 'LIP', name: 'Licença por Int. Particular'},
    { symbol: 'FE', name: 'Férias'}
  ]

  getDaysInMonth(month: any, year: any) {
    return new Date(year, month, 0).getDate();
  }

  createDaysArray(dateSelected: Date) {
    const date = dateSelected;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const daysInMonth = this.getDaysInMonth(month, year);

    const daysArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      const dayName = new Date(dateString).toLocaleDateString('pt-BR', { weekday: 'long' }).slice(0, 3).toUpperCase()
      daysArray.push({ day, dayName, dateString });
    }
    return daysArray;
  }

  listItens(colaborador: any) {
    const listAny = []
    if(colaborador.itens) {
      for(let day of this.listDay) {
        listAny.push({
          day: day.dateString,
        })
      }
      for(let item of colaborador.itens) {
        const find: any = listAny.findIndex(d => d.day == item.dataEscala)
        if(find != -1) {
          listAny[find] = {
            ...listAny[find],
            item
          }
        }
      }
    } else {
      return new Array(this.listDay.length).fill({
        day: 0
      })
    }
    return listAny
  }

  loadPerson(setorId: number, colaboradores: any) {
    const data = [];
    for(let colaborador of colaboradores!) {
      data.push({
        name: colaborador.nome,
        colaborador,
        ch: colaborador.cargaHoraia,
        items: [
          ...this.listItens(colaborador)
        ]
      })
    }

    this.persons.push({
      setorId: setorId,
      data
    })
  }

  data: { ch: number, data: any } =
    {
      ch: 120,
      data: [
        'SD', '' ,'SN', '', '',
        'MT', 'MT' ,'MT', 'SD', '',
        'SD', '' ,'SN', '', '',
        'LM', 'LM' ,'SN', '', 'LM',
        'SD', '' ,'SN', '', '',
        'SD', '' ,'SN', '', 'LM'
      ]
    }

  tableData: { name: string, ch: number, data: any}[] = [
    {
      name: 'Diego João Barbosa',
      ...this.data
    },
    {
      name: 'Nicolas Murilo Cardoso',
      ...this.data
    },
    {
      name: 'Ana Alana Priscila da Rosa',
      ...this.data
    },    {
      name: 'Lorena Bárbara de Paula',
      ...this.data
    }
  ]

  initFor() {
    console.log('teste')
    for(let i = 0; i < 100; i++) {
      this.tableData.push(    {
        name: 'Fake Data',
        ...this.data
      },)
    }
  }

  isBg(data: any): boolean {
    if(data =='LM' || data == 'FE') {
      return true;
    } else{
      return false;
    }
  }

  close() {
    this.modalCtrl.dismiss()
  }
}
