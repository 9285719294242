<ion-list>
  <ion-item class="ion-margin-bottom" lines="none" detail="false">
    <ion-buttons slot="start">
      <ion-img class="icon" src="assets/logo-small.png" routerLink=""></ion-img>
    </ion-buttons>
    <ion-img aria-hidden="true" src="assets/logo.png" routerLink=""></ion-img>
  </ion-item>

  <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
    <ion-item
      routerDirection="root"
      [routerLink]="[p.url]"
      lines="none"
      detail="false"
      routerLinkActive="selected"
    >
      <ion-icon
        aria-hidden="true"
        slot="start"
        [ios]="p.icon + '-outline'"
        [md]="p.icon + '-sharp'"
      ></ion-icon>
      <ion-label>{{ p.title }}</ion-label>
    </ion-item>
  </ion-menu-toggle>
  <ion-item
    slot="end"
    lines="none"
    detail="false"
    routerDirection="root"
    [routerLink]="['account']"
    >
  <ion-icon
    aria-hidden="true"
    slot="start"
    [ios]="'person'"
    [md]="'person'"
    ></ion-icon>
    <ion-label>Conta</ion-label>
  </ion-item>

  <ion-item
      slot="end"
      lines="none"
      detail="false"
      (click)="logout()"
      routerDirection="root"
      [routerLink]="['login']"
      >
    <ion-icon
      aria-hidden="true"
      slot="start"
      [ios]="'log-out-outline'"
      [md]="'log-out-sharp'"
      ></ion-icon>
      <ion-label>Sair</ion-label>
    </ion-item>
</ion-list>
