<ion-toolbar>
  <ion-buttons slot="end">
    <ion-button (click)="close()"
      ><ion-icon name="close-outline"></ion-icon
    ></ion-button>
  </ion-buttons>
</ion-toolbar>
<ion-content class="ion-padding">
  <div class="container">
    <h1>Sugerir Regra</h1>
    <input type="text" [(ngModel)]="suggest" placeholder="Regra">
    <button class="btn" (click)="suggestSubmit()">Sugerir</button>

  </div>
</ion-content>
