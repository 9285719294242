import { Observable, throwError } from "rxjs";
import { Escala } from "src/app/models/escala";
import { Estatistica } from "src/app/models/estatistica";
import { ScaleStore } from "../Scale.store";

export abstract class EscalaService {


  protected scaleStore: ScaleStore;
  constructor(scaleStore: ScaleStore) {
    this.scaleStore = scaleStore;
  }

    protected handleError(error: any) {
        return throwError(error);
    }

    protected log(message: string) {
        console.log('============= message response ============');
        console.log(message);
    }

    public abstract carregaEscalas(localId: number): Observable<Escala[]>;
    public abstract criaEscala(escala: Escala): Observable<Escala>;
    public abstract atualizarEscala(escalaId: number, changes: Partial<Escala>): Observable<Escala>;
    public abstract filtrarEscalaPorLocal(localId: number): Observable<Escala[]>;
    public abstract deletarEscala(escalaId: number): void;
    public abstract getEscalas(localId: number): Observable<Escala[]>;
    public abstract getEscalaById(id: number): Observable<Escala>;


    public abstract removerColaboradorEscala(escalaId: number, colaboradorId: number): Observable<any>;

    public abstract carregaEstatisticas(): Observable<Estatistica[]>;
    public abstract criaEstatistica(estatistica: Estatistica): Observable<Estatistica>;
    public abstract filtrarEstatistica(estatisticaId: number): Observable<Estatistica>;
    public abstract filtrarEstatisticaPorEscala(escalaId: number): Observable<Estatistica[]>;
    public abstract aplicarEmMassaEscala(turnoId: number, escalaId: number, items: { colaboradorId: number, date: string, setorId: number }[] ): Observable<any>

    public abstract sugerirRegra(sugestao: String): Observable<any>;
    public abstract carregaRegrasSugeridas(): Observable<any>;


    public limparData(): void {
      this.scaleStore.clearData();
    }

}
