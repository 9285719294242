import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScaleChangeRequestsComponent } from './components/scale-change-requests/scale-change-requests.component';
import { CreateSetorComponent } from './components/create-setor/create-setor.component';
import { CreateTurnoComponent } from './components/create-turno/create-turno.component';
import { NgxMaskModule } from 'ngx-mask';
import { TurnoItemComponent } from './components/create-turno/turno-item/turno-item.component';
import { SetorItemComponent } from './components/create-setor/setor-item/setor-item.component';
import { ForgotPasswordModalComponent } from './components/forgot-password/forgot-password.component';
import { CreateEventCollaboratorsModalComponent } from './components/create-event-collaborators/create-event-collaborators.component';
import { SuggestRuleModalComponent } from './components/suggest-rule/suggest-rule.component';

@NgModule({
  declarations: [
    ScaleChangeRequestsComponent,
    CreateSetorComponent,
    CreateTurnoComponent,
    TurnoItemComponent,
    SetorItemComponent,
    ForgotPasswordModalComponent,
    CreateEventCollaboratorsModalComponent,
    SuggestRuleModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ]
})
export class ModalsModule { }
