import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover-text',
  templateUrl: './popover-text.component.html',
  styleUrls: ['./popover-text.component.scss'],
})
export class PopoverTextComponent {
  @Input() message!: string;

  constructor() {}
}
