import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, find } from 'rxjs';
import { ScaleInUse } from '../models/scale-in-use';

@Injectable({
  providedIn: 'root'
})
export class ScaleInUseService {

  private courierSubject = new BehaviorSubject<ScaleInUse | null>(null);
  courier$ = this.courierSubject.asObservable();

  constructor() { }

  update(courierData: Partial<ScaleInUse>) {
      const newCourier = {
        ...(this.courierSubject.value != null ? this.courierSubject.value : {}),
        ...courierData
      }
      this.courierSubject.next(newCourier);
      console.log(newCourier, 'ScaleInUseService updated')
  }

  getObservable(): Observable<ScaleInUse | null> {
    return this.courier$;
  }

  getValues(): ScaleInUse | null {
    return this.courierSubject.value;
  }

  getEscalaId(): number | null {
    if(this.courierSubject.value?.escala) {
      return this.courierSubject.value.escala.id
    }
    return null;
  }

  reset(): void {
    this.courierSubject.next(null)
  }

  addSetorInEscaleInUse(setor: any) {
    const data = this.courierSubject.value;
    const newSetor = {
      ...setor,
      colaboradores: []
    }
    if(data && data.escala) {
      data.escala.setores?.push(newSetor);
      console.log(newSetor, 'ScaleInUseService new Setor');
      this.courierSubject.next(data);
    }

  }

  removeSetorInEscaleInUse(setorId: number) {
    const data = this.courierSubject.value;
    if(!(data?.escala && data)) return;
    const findIndex = data.escala.setores!.findIndex(setorF => setorF.id === setorId)
    if(findIndex > -1) {
      data.escala.setores?.splice(findIndex, 1);
      console.log(setorId, 'ScaleInUseService Setor removed');
      this.courierSubject.next(data);
    }
  }

  updateSetorInEscaleInUse(setorId: number, newData: any) {
    const data = this.courierSubject.value;
    if(!(data?.escala && data)) return;
    const findIndex = data.escala.setores!.findIndex(setorF => setorF.id === setorId)
    console.log('updateSetorInEscaleInUse ')
    if(findIndex > -1) {
      data.escala.setores![findIndex] = {
        ...data.escala.setores![findIndex],
        ...newData
      };
      console.log(setorId, 'ScaleInUseService Setor update');
      this.courierSubject.next(data);
    }
  }
}
