import { environment } from "src/environments/environment";

export enum EndPoint {
  LOCAL = 'api/localTrabalho',
  ADMINUSER = 'api/adminUser',
  EMPRESA = 'api/empresa',
  COLABORADOR = 'api/colaborador',
  ESCALA = 'api/escala',
  LOGIN = 'api/public/login',
  NEW_LOGIN = 'api/public/newUser',
  SETOR = 'api/setor',
  TURNO = 'api/turnos',
  ITEM_COLABORADOR = 'api/escala/itens',
  RECUPERAR_SENHA = 'api/public/recovery'
}

function getRootEndpoint() {
  return (document.URL.indexOf("http://localhost:81") == 0 ? environment.proxy : environment.api) + "";
}

export function getEndpoint(endpoint: EndPoint): string {
  switch(endpoint) {
    case EndPoint.LOCAL:
      return `${getRootEndpoint()}${EndPoint.LOCAL}`
    case EndPoint.ADMINUSER:
      return `${getRootEndpoint()}${EndPoint.ADMINUSER}`
    case EndPoint.EMPRESA:
      return `${getRootEndpoint()}${EndPoint.EMPRESA}`
    case EndPoint.COLABORADOR:
      return `${getRootEndpoint()}${EndPoint.COLABORADOR}`
    case EndPoint.ESCALA:
      return `${getRootEndpoint()}${EndPoint.ESCALA}`
    case EndPoint.LOGIN:
      return `${getRootEndpoint()}${EndPoint.LOGIN}`
    case EndPoint.NEW_LOGIN:
      return `${getRootEndpoint()}${EndPoint.NEW_LOGIN}`
    case EndPoint.TURNO:
      return `${getRootEndpoint()}${EndPoint.TURNO}`
    case EndPoint.SETOR:
      return `${getRootEndpoint()}${EndPoint.SETOR}`
    case EndPoint.ITEM_COLABORADOR:
      return `${getRootEndpoint()}${EndPoint.ITEM_COLABORADOR}`
      case EndPoint.RECUPERAR_SENHA:
        return `${getRootEndpoint()}${EndPoint.RECUPERAR_SENHA}`
    default:
      return '';
  }
}
