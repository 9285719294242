<ion-toolbar>
  <ion-buttons slot="end">
    <ion-button (click)="close()"
      ><ion-icon name="close-outline"></ion-icon
    ></ion-button>
  </ion-buttons>
</ion-toolbar>
<ion-content>
  <h2>RECUPERE SUA SENHA</h2>
  <input type="email" placeholder="Email" [(ngModel)]="email">
  <button (click)="sendRequest()" class="btn">ENVIAR</button>

</ion-content>
