import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './components/header/header.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { PopoverTextComponent } from './components/popover-text/popover-text.component';
import { ExportPdfComponent } from './components/export-pdf/export-pdf.component';
import { TableComponent } from './components/export-pdf/table/table.component';
import { HeaderLayoutComponent } from './components/header-detail/header.component';
import { TesteComponentComponent } from './components/teste-component/teste-component.component';
import { PopoverSelectColorComponent } from './components/popover-select-color/popover-select-color.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  exports: [
    HeaderComponent,
    CheckboxComponent,
    PopoverTextComponent,
    ExportPdfComponent,
    HeaderLayoutComponent,
    TesteComponentComponent,
    PopoverSelectColorComponent
  ],
  declarations: [
    HeaderComponent,
    CheckboxComponent,
    PopoverTextComponent,
    ExportPdfComponent,
    TableComponent,
    HeaderLayoutComponent,
    TesteComponentComponent,
    PopoverSelectColorComponent
  ]
})
export class SharedPageModule {}
