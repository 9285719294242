import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import { tap } from 'rxjs';
import { getColor, getColors, getCorPorValor } from 'src/app/models/colors';
import { ScaleInUse } from 'src/app/models/scale-in-use';
import { Turno } from 'src/app/models/turno';
import { LoadingService } from 'src/app/services/loading.service';
import { ScaleInUseService } from 'src/app/services/scale-in-use.service';
import { TurnoService } from 'src/app/services/turno/turno.service';
import { PopoverSelectColorComponent } from 'src/app/shared/components/popover-select-color/popover-select-color.component';
import { calculeHoursShift } from 'src/app/shared/utils';

@Component({
  selector: 'turno-item',
  templateUrl: './turno-item.component.html',
  styleUrls: ['./turno-item.component.scss'],
})
export class TurnoItemComponent  implements OnInit {

  @Input() isInput!: boolean;
  @Input() turno!: Turno;
  @Output() FormEmitter: EventEmitter<boolean> = new EventEmitter<boolean>()
  colorTurno!: string;
  active!: boolean;

  isEdit: boolean = false;

  formGroup!: FormGroup;

  colorSelected = '#E54997'
  h1!: string;
  h2!: string;
  turnoName!: string;

  constructor(
    private turnoService: TurnoService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingService,
    private popoverCtrl: PopoverController,
    private scaleInUse: ScaleInUseService
    ) { }

  ngOnInit() {
    if(this.turno) {
      this.h1 = this.turno.horaInicio
      this.h2 = this.turno.horaFim
      this.colorTurno = this.getColor(this.turno.cor) || '#1FBAA4';
      this.active = this.turno.ativo;
    }
    if(this.isInput) {
      this.createForm()
    }
  }

  createForm() {
    this.formGroup = new FormGroup({
      descricao: new FormControl(this.turno ? this.turno.descricao : '', [Validators.required]),
      sigla: new FormControl(this.turno ? this.turno.sigla : '', [Validators.required] ),
      cor: new FormControl(this.turno ? this.turno.cor : 'color1', [Validators.required]),
      ativo: new FormControl(this.turno ? this.turno.ativo : true, [Validators.required]),
      horaInicio: new FormControl(this.turno ? this.turno.horaInicio : '00:00', [Validators.required]),
      horaFim: new FormControl(this.turno ? this.turno.horaFim : '00:00', [Validators.required]),
    })
    this.colorSelected = '#1FBAA4'
    if(!this.isEdit) {
      this.turnoService.getCoresSelecionadas(this.scaleInUse.getValues()!.escala!.id, false).pipe(
        tap((r) => {
          const colors = this.getAllColors().map((c) => getCorPorValor(c));
          const notSelected = colors.filter(c => !r.includes(c))
          this.colorSelected = getColor(notSelected[0]);
          this.formGroup.patchValue({ cor: notSelected[0] })
        })
      ).subscribe()
    }
    if(this.isEdit) {
      this.colorSelected = this.getColor(this.turno.cor)!;
    }
  }

  getColor(name: string) {
    return getColor(name)
  }

  getAllColors() {
    return getColors();
  }

  calcShow() {
    return calculeHoursShift(this.h1, this.h2)
  }

  calcInput() {
    return calculeHoursShift(this.formGroup.value.horaInicio, this.formGroup.value.horaFim)
  }

  submit() {
    if(this.formGroup.invalid) {
      this.loadingCtrl.presentToast('Por favor, preencha todos os campos.')
      return;
    }
    if(this.calcInput() == '0h' || this.calcInput() == '0h00') {
      this.loadingCtrl.presentToast('Horas invalidas!')
      return;
    }
    console.log('PASSED')
    // TODO: ver porque ao editar esta duplicando o turno.
    console.log(this.isEdit, 'in edditing')
    if(!this.isEdit) {
      this.turnoService.criaTurno({
        ...this.formGroup.value
      }).pipe(
        tap(() => {
          this.loadingCtrl.presentToast('Turno criado com sucesso')
          this.formGroup.reset()
          this.createForm()
          this.isInput = false;
          this.FormEmitter.emit(false);
          // this.modalCtrl.dismiss()
        })
      ).subscribe()
    } else {
      this.turnoService.atualizarTurno(this.turno.id,{
        ...this.formGroup.value
      }).pipe(
        tap((res) => {
          this.loadingCtrl.presentToast('Turno editado com sucesso');
          this.isEdit = false;
          this.isInput = false;
          this.formGroup.reset();
          this.turno = res;
          // this.modalCtrl.dismiss()
        })
      ).subscribe()
    }
  }

  setEditMode() {
    this.isEdit = true;
    this.isInput = true;
    this.createForm();
  }

  cancel() {
    if(this.isEdit) {
      this.isEdit = false;
      this.isInput = false;
      this.formGroup.reset();
      return;
    }
    this.formGroup.reset();
    this.createForm();
    this.isInput = false;
    this.FormEmitter.emit(false);
  }

  deleteTurno() {
    this.turnoService.deletarTurno(this.turno.id)
  }

  async openPopover(event: any) {
    const popover = await this.popoverCtrl.create({
      component: PopoverSelectColorComponent,
      side: 'top',
      mode: 'ios',
      event,
      componentProps: {
        scaleId: this.scaleInUse.getEscalaId(),
        isSector: false
      }
    })

    popover.present();

    popover.onDidDismiss().then((r) => {
      if(r.data != undefined) {
        this.colorSelected = getColor(r.data);
        this.formGroup.patchValue({ cor: r.data })
      }
    })
  }

}
