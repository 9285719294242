export enum Colors {
  color1 = '#1FBAA4',
  color2 = '#3F811A',
  color3 = '#B0BA1F',
  color4 = '#EFAA32',
  color5 = '#C83131',
  color6 = '#E54997',
  color7 = '#7A24AA',
  color8 = '#4E1ED4',
  color9 = '#2699FB',
  color10 = '#DF3030',
  color11 = '#BC6138',
  color12 = '#FF9900',
  color13 = '#FFD500',
  color14 = '#BBCE10',
  color15 = '#3BD12B',
  color16 = '#059B00',
  color17 = '#08E9D2',
  color18 = '#06BBFF',
  color19 = '#0066FF',
  color20 = '#2822CE',
  color21 = '#9635EB',
  color22 = '#6F0DC5',
  color23 = '#CC008F',
  color24 = '#FC4DC8'
}

export function getColor(cor: string): string {
  const corEnum = Colors[cor as keyof typeof Colors];
  return corEnum ? corEnum : Colors.color1;
}

export function getColors(): string[] {
  return Object.values(Colors);
}

export function getCorPorValor(valor: string): string  {
  for (const cor in Colors) {
    if (Colors[cor as keyof typeof Colors] === valor) {
      return cor;
    }
  }
  return 'color1';
}
